<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入店铺名称" style="width: 220px;" class="filter-item" clearable/>
      <el-select class="filter-item" filterable v-model="listQuery.canteen_id" placeholder="请选择食堂" style="width: 140px" clearable>
        <el-option v-for="item in canteens" :key="item.id" :label="item.canteen_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.shop_type" placeholder="请选择类型" style="width: 140px" clearable>
        <el-option label="食堂美食" :value="1"></el-option>
        <!-- <el-option label="商城" :value="2"></el-option>
        <el-option label="夜猫店" :value="3"></el-option> -->
      </el-select>
      <el-select class="filter-item" v-model="listQuery.status" placeholder="请选择状态" style="width: 140px" clearable>
        <el-option label="营业中" value="service"></el-option>
        <el-option label="休息中" value="rest"></el-option>
        <el-option label="已下架" value="close"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleAdd">添加</el-button>
    </div>
    <div class="filter-container">
      <el-button-group>
        <el-button type="primary" size="small" :disabled="ids.length <= 0" @click="handleShow(1)">批量上架</el-button>
        <el-button type="warning" size="small" :disabled="ids.length <= 0" @click="handleShow(0)">批量下架</el-button>
        <el-button type="danger" size="small" :disabled="ids.length <= 0" @click="handleDel">批量删除</el-button>
        <el-button type="info" size="small" :disabled="ids.length <= 0" @click="handleCopy">复制添加</el-button>
      </el-button-group>
    </div>
    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="selection" align="center" width="40"></el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="食堂" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.canteen ? scope.row.canteen.canteen_name : '' }}
        </template>
      </el-table-column>
      <el-table-column label="店铺名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop_name }}
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.shop_type == 1">外卖</span>
          <!-- <span v-else-if="scope.row.shop_type == 2">商城</span>
          <span v-else-if="scope.row.shop_type == 3">夜猫店</span> -->
        </template>
      </el-table-column>
      <el-table-column label="封面" min-width="110" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.thumb" class="user-avatar">
        </template>
      </el-table-column>
      <el-table-column label="营业状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_show == 0" type="danger">已下架</el-tag>
          <el-tag v-else-if="scope.row.in_service == 1" type="primary">营业中</el-tag>
          <el-tag v-else-if="scope.row.in_service == 0" type="info">休息中</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="电话" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.tel }}
        </template>
      </el-table-column>
      <el-table-column label="管理员" min-width="120" align="center">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.shop_manager" :key="index">
            <span>{{item.name}}</span>
            <span v-if="index < scope.row.shop_manager.length - 1">&nbsp;|&nbsp;</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <div>
            <el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="primary" size="mini" @click="handleManager(scope.row)">管理员</el-button>
          </div>
          <div style="margin-top: 5px">
            <el-button type="success" size="mini" @click="handleToCategory(scope.row)">分类</el-button>
            <el-button type="success" size="mini" @click="handleToGoods(scope.row)">商品</el-button>
          </div>
          <div style="margin-top: 5px" v-if="user.user.type === 'admin' || user.permissions.indexOf('sp') !== -1">
            <el-button type="warning" size="mini" @click="handleLedgerEdit(scope.row)">设置分账</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="110px" class="form">
        <el-form-item label="类型">
          <el-select v-model="form.shop_type" placeholder="请选择分类" style="width: 140px">
            <el-option label="食堂美食" :value="1"></el-option>
            <!-- <el-option label="商城" :value="2"></el-option>
            <el-option label="夜猫店" :value="3"></el-option> -->
          </el-select>
          <el-select v-if="form.shop_type == 1" class="filter-item" v-model="form.canteen_id" placeholder="请选择食堂" style="width: 140px" clearable>
            <el-option v-for="item in canteens" :key="item.id" :label="item.canteen_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类">
          <el-select v-model="form.shop_category_ids" multiple collapse-tags placeholder="请选择分类，可不填" style="width: 220px" clearable>
            <el-option v-for="item in shopCategories" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input v-model="form.shop_name" placeholder="请输入店铺名称" />
        </el-form-item>
        <el-form-item label="公告">
          <el-input v-model="form.description" placeholder="请输入公告" />
        </el-form-item>
        <el-form-item label="封面">
          <upload-one v-model="form.thumb"></upload-one>
          <div style="font-size:12px;color:red;">*推荐尺寸：330*330</div>
        </el-form-item>
        <el-form-item label="资质档案">
          <upload-list v-model="form.profile_imgs"></upload-list>
          <div style="font-size:12px;color:red;">营业执照、食品生产许可证等，可添加多张</div>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.tel" placeholder="请输入联系电话" />
          <div style="font-size: 12px;color: red">格式：固话(如05523636366)、手机号码(如19712341234)</div>
        </el-form-item>
        <el-form-item label="是否营业">
          <el-switch v-model="form.in_service" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="营业星期">
          <el-checkbox-group v-model="form.service_weekday" size="small">
            <div>
              <el-checkbox :label="1" size="mini" border>星期一</el-checkbox>
              <el-checkbox :label="2" size="mini" border>星期二</el-checkbox>
              <el-checkbox :label="3" size="mini" border>星期三</el-checkbox>
              <el-checkbox :label="4" size="mini" border>星期四</el-checkbox>
            </div>
            <div>
              <el-checkbox :label="5" size="mini" border>星期五</el-checkbox>
              <el-checkbox :label="6" size="mini" border>星期六</el-checkbox>
              <el-checkbox :label="7" size="mini" border>星期日</el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="营业时间">
          <el-tag v-if="form.service_time.length <= 0">全天</el-tag>
          <el-tag v-else :key="index" v-for="(item,index) in form.service_time" closable @close="handleCloseTag(index)" style="margin-right: 3px">
            {{item[0]}} ~ {{item[1]}}
          </el-tag>
          <el-popover
            placement="right"
            v-model="showTimePicker">
            <p>
              <el-time-picker is-range size="small" v-model="timeArr" value-format="HH:mm:00" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
          </el-time-picker>
          </p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="showTimePicker = false;">取消</el-button>
              <el-button type="primary" size="mini" @click="chooseTime">确定</el-button>
            </div>
            <el-button slot="reference" class="button-new-tag" size="small">+ 添加</el-button>
          </el-popover>

          <!-- <el-button icon="el-icon-plus" size="small" type="primary" @click="chooseTime" :disabled="!timeArr"></el-button> -->
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="打印机编号">
              <el-input v-model="form.printer_no" placeholder="请输入打印机编号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="打印机密钥">
              <el-input v-model="form.printer_key" placeholder="请输入打印机密钥" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="打印联数">
          <el-input-number v-model="form.printer_number" :min="1" :max="99"></el-input-number>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="标签打印机编号">
              <el-input v-model="form.label_printer_no" placeholder="请输入标签打印机编号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标签打印机密钥">
              <el-input v-model="form.label_printer_key" placeholder="请输入标签打印机密钥" />
            </el-form-item>
          </el-col>
        </el-row>

        <div v-show="user.user.type === 'admin' || user.user.type === 'school'">
<!--          <el-form-item label="首页推荐">-->
<!--            <el-switch v-model="form.is_promote" :active-value="1" :inactive-value="0"></el-switch>-->
<!--          </el-form-item>-->
          <el-form-item label="推荐权重">
            <el-input v-model="form.promote_weight" type="number" min="0" placeholder="请输入推荐权重" />
          </el-form-item>
          <el-form-item label="权重">
            <el-input v-model="form.weight" type="number" min="0" placeholder="请输入权重" />
          </el-form-item>
        </div>

        <div v-show="user.user.type === 'admin'">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="起送价格">
                <el-input v-model="form.send_limit" type="number" min="0" placeholder="请输入起送价格，单位元" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="配送费">
                <el-input v-model="form.send_fee" type="number" min="0" placeholder="请输入配送费，单位元" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="管理费">
            <el-input v-model="form.manage_fee" type="number" min="0" placeholder="请输入管理费" />
          </el-form-item>

          <el-form-item label="商品溢价">
            <el-input v-model="form.goods_extra_price" type="number" min="0" placeholder="单位元" />
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="投餐配送费">
                <el-input v-model="form.sideboard_send_fee" type="number" min="0" placeholder="请输入投餐配送费，单位元" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上楼配送费">
                <el-input v-model="form.upstairs_send_fee" type="number" min="0" placeholder="请输入上楼配送费，单位元" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="现场收款">
            <el-switch v-model="form.is_collect_money" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="单笔最小金额">
                <el-input v-model="form.collect_money_range.min_amount" type="number" min="0" placeholder="请输入单笔最小金额" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单笔最大金额">
                <el-input v-model="form.collect_money_range.max_amount" type="number" min="0" placeholder="请输入单笔最大金额" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisibleLedger" :close-on-click-modal="false">
      <el-form ref="ledgerForm" :model="ledgerForm" label-position="left" label-width="90px" class="form">
        <el-form-item label="特约商户号">
          <el-input v-model="ledgerForm.sub_mch_id" placeholder="请输入特约商户号" />
        </el-form-item>
        <el-form-item label="分账角色">
          <el-checkbox-group v-model="ledgerForm.ledger_role_ids">
            <el-checkbox v-for="item in schoolLedgerRoles" v-if="item.is_use" :key="item.id" :label="item.id">{{item.role_name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleLedger = false">取消</el-button>
        <el-button type="primary" @click="saveDataLedger" :loading="btnLoadingLedger">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      shopCategories: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        canteen_id: "",
        status: "",
        shop_type: "",
      },
      btnLoading: false,
      dialogVisible: false,
      ids: [],
      form: {
        id: "",
        school_id: "",
        canteen_id: "",
        shop_type: 1,
        shop_name: "",
        description: "",
        thumb: "",
        tel: "",
        in_service: 1,
        shop_category_ids: [],
        service_weekday: [],
        service_time: [],
        weight: 0,
        promote_weight: 0,
        send_limit: 0,
        send_fee: 0,
        goods_extra_price: 0,
        manage_fee: 0,
        is_promote: 0,
        printer_no: "",
        printer_key: "",
        printer_number: "",
        profile_imgs: [],
        sub_mch_id: "",
        ledger_role_ids: [],
        sideboard_send_fee: 0,
        upstairs_send_fee: 0,
        is_collect_money: 0,
        collect_money_range: {
          min_amount: 0,
          max_amount: 200,
        },
        label_printer_no: "",
        label_printer_key: "",
      },
      showTimePicker: false,
      timeArr: null,
      canteens: [],

      shop_manager: null,
      schoolLedgerRoles: [],
      ledgerForm: {
        id: "",
        sub_mch_id: "",
        ledger_role_ids: [],
      },
      dialogVisibleLedger: false,
      btnLoadingLedger: false,
    };
  },
  created() {
    this.getList();
    this.getCanteenList();
    this.getShopCategoryList();
    this.getSchoolLedgerRoleList();
  },
  computed: {
    // 已选择的角色
    selectedRoleId: function() {
      var ledger_role_ids = []
      for(var i=0;i<this.form.ledger_role_ids.length;i++){
        for(var j=0;j<this.schoolLedgerRoles.length;j++){
          if(this.form.ledger_role_ids[i] === this.schoolLedgerRoles[j].id && this.schoolLedgerRoles[j].is_use){
            ledger_role_ids.push(this.schoolLedgerRoles[j].id);
          }
        }
      }
      return ledger_role_ids;

    },
    selectedRoleIdLedger: function() {
      var ledger_role_ids = []
      for(var i=0;i<this.ledgerForm.ledger_role_ids.length;i++){
        for(var j=0;j<this.schoolLedgerRoles.length;j++){
          if(this.ledgerForm.ledger_role_ids[i] === this.schoolLedgerRoles[j].id && this.schoolLedgerRoles[j].is_use){
            ledger_role_ids.push(this.schoolLedgerRoles[j].id);
          }
        }
      }
      return ledger_role_ids;

    },
    ...mapGetters(["schools", "school_id","user"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getCanteenList();
      this.getShopCategoryList();
      this.getSchoolLedgerRoleList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          canteen_id: "",
          shop_type: 1,
          shop_name: "",
          description: "",
          thumb: "",
          tel: "",
          in_service: 1,
          shop_category_ids: [],
          service_weekday: [],
          service_time: [],
          weight: 0,
          promote_weight: 0,
          send_limit: 0,
          send_fee: 0,
          goods_extra_price: 0,
          manage_fee: 0,
          is_promote: 0,
          printer_no: "",
          printer_key: "",
          printer_number: "",
          profile_imgs: [],
          sub_mch_id: "",
          ledger_role_ids: [],
          sideboard_send_fee: 0,
          upstairs_send_fee: 0,
          is_collect_money: 0,
          collect_money_range: {
            min_amount: 0,
            max_amount: 200,
          },
          label_printer_no: "",
          label_printer_key: "",
        };
        this.timeArr = null
      }
    },
    dialogVisibleLedger(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.ledgerForm = {
          id: "",
          sub_mch_id: "",
          ledger_role_ids: [],
        };
        this.timeArr = null
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getCanteenList() {
      if (!this.school_id) {
        this.canteens = [];
        return;
      }
      request({
        url: "/api/backend/canteen/list",
        method: "get",
        params: {
          page: 0,
          limit: 1000000,
          school_id: this.school_id
        }
      }).then(response => {
        this.canteens = response.data.data;
      });
    },
    getShopCategoryList() {
      request({
        url: "/api/backend/shopCategory/list",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          school_id: this.school_id
        }
      }).then(response => {
        this.shopCategories = response.data.data;
      });
    },
    getSchoolLedgerRoleList() {
      request({
        url: "/api/backend/school/ledgerRoleList",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          school_id: this.school_id
        }
      }).then(response => {
        this.schoolLedgerRoles = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach(element => {
        ids.push(element.id);
      });
      this.ids = ids;
    },
    handleCloseTag(index) {
      this.form.service_time.splice(index, 1);
    },
    chooseTime() {
      if (this.timeArr) {
        this.form.service_time.push(this.timeArr);
      }
      this.showTimePicker = false
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    handleCopy() {
      let item = null;
      this.list.forEach(element => {
        if (element.id === this.ids[0]) {
          item = element;
        }
      });
      this.form = {
        id: "",
        school_id: item.school_id,
        canteen_id: item.canteen_id,
        shop_type: item.shop_type,
        shop_name: item.shop_name,
        description: item.description,
        thumb: item.thumb,
        tel: item.tel,
        in_service: item.in_service,
        shop_category_ids: item.shop_category_ids,
        service_weekday: item.service_weekday,
        service_time: item.service_time,
        weight: item.weight,
        promote_weight: item.promote_weight,
        send_limit: item.send_limit,
        send_fee: item.send_fee,
        goods_extra_price: item.goods_extra_price,
        manage_fee: item.manage_fee,
        is_promote: item.is_promote,
        printer_no: item.printer_no,
        printer_key: item.printer_key,
        printer_number: item.printer_number,
        profile_imgs: item.profile_imgs || [],
        sub_mch_id: item.sub_mch_id,
        ledger_role_ids: item.ledger_role_ids || [],
        sideboard_send_fee: item.sideboard_send_fee,
        upstairs_send_fee: item.upstairs_send_fee,
        is_collect_money: item.is_collect_money,
        collect_money_range: item.collect_money_range || {min_amount: 0, max_amount: 200},
        label_printer_no: item.label_printer_no,
        label_printer_key: item.label_printer_key,
      };
      this.dialogVisible = true;
    },
    async handleEdit(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.school_id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      this.form = {
        id: item.id,
        school_id: item.school_id,
        canteen_id: item.canteen_id,
        shop_type: item.shop_type,
        shop_name: item.shop_name,
        description: item.description,
        thumb: item.thumb,
        tel: item.tel,
        in_service: item.in_service,
        shop_category_ids: JSON.parse(JSON.stringify(item.shop_category_ids)),
        service_weekday: JSON.parse(JSON.stringify(item.service_weekday)),
        service_time: JSON.parse(JSON.stringify(item.service_time)),
        weight: item.weight,
        promote_weight: item.promote_weight,
        send_limit: item.send_limit,
        send_fee: item.send_fee,
        goods_extra_price: item.goods_extra_price,
        manage_fee: item.manage_fee,
        is_promote: item.is_promote,
        printer_no: item.printer_no,
        printer_key: item.printer_key,
        printer_number: item.printer_number,
        profile_imgs: item.profile_imgs || [],
        sub_mch_id: item.sub_mch_id,
        ledger_role_ids: item.ledger_role_ids || [],
        sideboard_send_fee: item.sideboard_send_fee,
        upstairs_send_fee: item.upstairs_send_fee,
        is_collect_money: item.is_collect_money,
        collect_money_range: item.collect_money_range || {min_amount: 0, max_amount: 200},
        label_printer_no: item.label_printer_no,
        label_printer_key: item.label_printer_key,
      };
      this.form.ledger_role_ids = this.selectedRoleId
      this.dialogVisible = true;
    },
    async handleLedgerEdit(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.school_id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      this.ledgerForm = {
        id: item.id,
        sub_mch_id: item.sub_mch_id,
        ledger_role_ids: item.ledger_role_ids || [],
      };
      this.ledgerForm.ledger_role_ids = this.selectedRoleIdLedger
      this.dialogVisibleLedger = true;
    },
    handleShow(show) {
      let text = show == 1 ? '上架' : '下架'
      this.$confirm("确定要"+text+"选中店铺吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/shop/isShow",
          method: "post",
          data: {
            ids: this.ids,
            is_show: show
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleDel() {
      this.$confirm("确定要删除选中店铺吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/shop/del",
          method: "post",
          data: {
            ids: this.ids
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleManager(item) {
      this.$router.push('/shop/shopManager?shop_id=' + item.id)
    },
    handleToCategory(item){
      this.$router.push('/shop/category?shop_id=' + item.id)
    },
    handleToGoods(item){
      this.$router.push('/shop/goods?shop_id=' + item.id)
    },
    saveData() {
      this.form.school_id = this.school_id
      if (!this.form.school_id) {
        this.$message({
          type: "warning",
          message: "请先选择学校"
        });
        return;
      }
      if (!this.form.shop_type) {
        this.$message({
          type: "warning",
          message: "请选择类型"
        });
        return;
      }
      if (!this.form.shop_name) {
        this.$message({
          type: "warning",
          message: "请输入店铺名称"
        });
        return;
      }
      if (!this.form.description) {
        this.$message({
          type: "warning",
          message: "请输入公告"
        });
        return;
      }
      if (!this.form.thumb) {
        this.$message({
          type: "warning",
          message: "请上传封面"
        });
        return;
      }
      if (this.form.tel.length != 11) {
        this.$message({
          type: "warning",
          message: "请输入正确的联系电话"
        });
        return;
      }
      if (this.form.send_limit < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的起送价格"
        });
        return;
      }
      if (this.form.send_fee < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的配送金额"
        });
        return;
      }
      if (this.form.goods_extra_price < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的商品溢价"
        });
        return;
      }
      if (this.form.manage_fee < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的管理费"
        });
        return;
      }
      if((!this.form.sub_mch_id && this.form.ledger_role_ids.length>0)){
        if (!this.form.sub_mch_id) {
          this.$message({
            type: "warning",
            message: "请输入特约商户号"
          });
          return;
        }
        if (this.form.ledger_role_ids.length===0) {
          this.$message({
            type: "warning",
            message: "请勾选分账角色"
          });
          return;
        }
      }
      if (this.form.sideboard_send_fee === "" || this.form.sideboard_send_fee < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的投餐配送费"
        });
        return;
      }
      if (this.form.upstairs_send_fee === "" || this.form.upstairs_send_fee < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的上楼配送费"
        });
        return;
      }
      if (this.form.is_collect_money && (this.form.collect_money_range.min_amount === "" || this.form.collect_money_range.max_amount === "")) {
        this.$message({
          type: "warning",
          message: "请输入单笔收款范围"
        });
        return;
      }

      this.btnLoading = true;
      request({
        url: "/api/backend/shop/save",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    saveDataLedger() {
      if((!this.ledgerForm.sub_mch_id && this.ledgerForm.ledger_role_ids.length>0)){
        if (!this.ledgerForm.sub_mch_id) {
          this.$message({
            type: "warning",
            message: "请输入特约商户号"
          });
          return;
        }
        if (this.ledgerForm.ledger_role_ids.length===0) {
          this.$message({
            type: "warning",
            message: "请勾选分账角色"
          });
          return;
        }
      }
      this.btnLoadingLedger = true;
      request({
        url: "/api/backend/shop/ledgerSave",
        method: "post",
        data: this.ledgerForm
      }).then(() => {
        this.dialogVisibleLedger = false;
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      })
      .finally(() => {
        this.btnLoadingLedger = false;
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
